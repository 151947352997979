<template>
  <TransitionRoot show as="template" appear>
    <Dialog as="div" class="relative z-10" @close="$emit('close')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"
        />
      </TransitionChild>

      <div
        class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20 flex items-center flex-row"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto w-[600px] max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
          >
            <Combobox :model-value="modelValue" @update:model-value="act">
              <div class="relative flex items-center px-2">
                <ArrowPathIcon
                  v-if="pending"
                  class="pointer-events-none ml-4 text-gray-600 size-5 animate-spin"
                />
                <MagnifyingGlassIcon
                  v-else
                  class="pointer-events-none ml-4 text-gray-600 size-5"
                />
                <ComboboxInput
                  class="h-12 w-full !bg-transparent text-gray-800 text-sm placeholder-gray-400 focus:ring-0 !border-0 !mb-0 focus:ring-offset-0 focus:outline-none focus-visible:outline-none"
                  placeholder="Search by email, name, content..."
                  @change="(e) => $emit('update:modelValue', e.target.value)"
                />
              </div>

              <ComboboxOptions
                v-if="data.length > 0"
                static
                class="max-h-96 scroll-py-3 overflow-y-auto p-3 list-none my-0 pt-2"
              >
                <!-- Only render 10 results in this component. -->
                <ComboboxOption
                  v-for="result in data.slice(0, 10)"
                  v-slot="{ active }"
                  :key="result.obj.id"
                  :value="result"
                >
                  <Result :item="result" :active="active" />
                </ComboboxOption>
                <div class="pt-3 -mx-3 px-3 mt-3 border-t border-t-gray-100">
                  <ActionButton
                    :to="{ name: '/search', query: { q: modelValue } }"
                    text="View all results &rarr;"
                    variant="primary"
                    @click="$emit('close')"
                  />
                </div>
              </ComboboxOptions>

              <div
                v-if="value !== '' && data.length === 0 && !pending"
                class="py-14 px-6 text-center text-sm sm:px-14"
              >
                <ExclamationTriangleIcon
                  icon="exclamation-triangle"
                  class="mx-auto size-6 text-gray-500"
                />
                <p class="mt-4 font-semibold text-gray-900">
                  No results found for {{ modelValue }}
                </p>
                <p class="mt-2 text-gray-500">
                  No components found for this search term. Please try again.
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { useRouter } from "vue-router/auto";

import ActionButton from "@/components/Utilities/ActionButton.vue";
import ArrowPathIcon from "@/icons/heroicons/arrow-path-micro.svg";
import ExclamationTriangleIcon from "@/icons/heroicons/exclamation-triangle-solid.svg";
import MagnifyingGlassIcon from "@/icons/heroicons/magnifying-glass-micro.svg";
import { generateAction } from "@/lib/search";
import { useStore } from "@/store/stores/scaffolding";
import { SearchResult } from "@/types/search_result";

import Result from "./Result.vue";

defineProps<{
  pending: boolean;
  data: SearchResult[];
  value: string;
}>();

const emit = defineEmits(["close", "update:modelValue"]);

const modelValue = defineModel<string>({
  required: true,
});

const router = useRouter();
const store = useStore();

const act = (item: SearchResult) => {
  const action = generateAction(item);
  if (action.type === "route") {
    router.push(action.route);
  } else if (action.type === "action") {
    if (action.action === "switch_newsletter") {
      const newsletter = store.account?.permissions.find(
        (n) => n.newsletter.id === action.payload.newsletter
      )?.newsletter;
      if (newsletter) {
        store.switchNewsletter(newsletter);
      }
    }
  }

  emit("close");
};
</script>
