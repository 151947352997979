<template>
  <div class="@container">
    <fieldset
      class="border-none m-0 p-0 grid grid-cols-1 @lg:grid-cols-2 @xl:grid-cols-4 gap-2"
    >
      <div
        v-for="option in options"
        :key="option.id"
        :class="{
          'border border-gray-300 rounded-lg p-2 flex flex-col justify-center text-center cursor-pointer hover:bg-blue-50 hover:border-blue-400 transition-colors relative only:col-span-2': true,
          '!border-blue-400 bg-blue-100 hover:bg-blue-200': modelValue.includes(
            option.id
          ),
        }"
        @click="handleUpdate(option.id)"
      >
        <div class="font-bold text-sm whitespace-nowrap truncate">
          {{ option.name }}
        </div>
        <div v-if="option.description" class="text-xs text-gray-500 pr-8">
          <Markdown>{{ option.description }}</Markdown>
        </div>
        <input
          type="checkbox"
          class="absolute right-2 rounded-full border-gray-300"
          :checked="modelValue.includes(option.id)"
        />
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import Markdown from "../Markdown";

type Option = {
  id: string;
  name: string;
  description?: string;
};

export default defineComponent({
  components: { Markdown },
  props: {
    options: Array as PropType<Option[]>,
    modelValue: {
      type: Array,
      required: true,
    },
    radio: Boolean,
  },
  emits: ["update:modelValue"],
  methods: {
    handleUpdate(option_id: string) {
      if (this.radio) {
        this.$emit("update:modelValue", [option_id]);
      } else if (this.modelValue.includes(option_id)) {
        this.$emit(
          "update:modelValue",
          this.modelValue.filter((option) => option !== option_id)
        );
      } else {
        this.$emit("update:modelValue", [...this.modelValue, option_id]);
      }
    },
  },
});
</script>
