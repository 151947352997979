<template>
  <label
    :for="props.id"
    class="flex items-center space-x-2 flex-1 justify-between mb-0"
  >
    <slot />
    <div class="text-sm tabular-nums">{{ props.count }}</div>
  </label>
</template>

<script lang="ts" setup>
const props = defineProps<{
  id: string;
  count: number | undefined;
}>();
</script>
