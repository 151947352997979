<template>
  <MenuItem
    v-slot="{ close }"
    :class="{
      'bg-blue-500 hover:!bg-blue-600': active,
      [variants({ variant }) || '']: true,
      'opacity-50 cursor-not-allowed pointer-events-none': disabled,
    }"
  >
    <RouterLink v-if="to" :to="to" class="no-underline">
      <div
        class="no-underline relative flex flex-1 transform-gpu items-center gap-3"
        @click="close"
      >
        <slot v-if="$slots.icon" name="icon" />
        <component
          :is="iconMappingMicro[icon]"
          v-else-if="icon"
          :class="{
            'size-4 transition-colors': true,
            'text-gray-400': !active,
            'text-white': active,
            'group-hover/item:text-white': variant === 'danger',
          }"
        />

        <div class="text-white flex-1">
          {{ text }}
        </div>
      </div>
    </RouterLink>
    <a
      v-else
      :href="href"
      :target="target"
      :class="{
        'no-underline relative flex flex-1 transform-gpu items-center gap-3': true,
      }"
    >
      <slot v-if="$slots.icon" name="icon" />
      <component
        :is="iconMappingMicro[icon]"
        v-else-if="icon"
        :class="{
          'size-4 transition-colors': true,
          'text-gray-400': !active,
          'text-white': active,
          'group-hover/item:text-white': variant === 'danger',
        }"
      />

      <div class="text-white flex-1">
        {{ text }}
      </div>

      <ArrowUpRightIcon v-if="external" class="size-4 -mr-1 text-gray-400" />
    </a>
  </MenuItem>
</template>

<script lang="ts" setup>
import { MenuItem } from "@headlessui/vue";
import { cva, VariantProps } from "class-variance-authority";

import ArrowUpRightIcon from "@/icons/heroicons/arrow-up-right-micro.svg";
import { iconMappingMicro, MappedIconMicro } from "@/icons/icon-mapping-micro";
import { type RouteLocation } from "@/router/types";

const variants = cva(
  "group/item relative flex cursor-pointer items-center rounded-[5px] border-none px-3 outline-none h-7 hover:bg-gray-700 text-sm transition-colors duration-75",
  {
    variants: {
      variant: {
        danger: "hover:!bg-red-600",
      },
    },
  }
);

type CVAProps = VariantProps<typeof variants>;

defineProps<{
  to?: RouteLocation;
  href?: string;
  text: string;
  icon?: MappedIconMicro;
  target?: string;
  active?: boolean;
  variant?: CVAProps["variant"];
  external?: boolean;
  disabled?: boolean;
}>();
</script>
