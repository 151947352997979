import { defineComponent, h, VNodeArrayChildren } from "vue";

import { renderMarkdownWithAllLinksAsTargetBlank } from "@/utils";

// Via https://v3.vuejs.org/guide/render-function.html#h-arguments.
function getChildrenTextContent(children: VNodeArrayChildren): string {
  return children
    .map((node: any) => {
      return typeof node.children === "string"
        ? node.children
        : Array.isArray(node.children)
        ? getChildrenTextContent(node.children)
        : "\n\n";
    })
    .join("\n\n");
}

export default defineComponent({
  render: function () {
    let text = this.$slots.default
      ? getChildrenTextContent(this.$slots.default())
      : "";
    return h("div", {
      innerHTML: renderMarkdownWithAllLinksAsTargetBlank(text),
    });
  },
});
