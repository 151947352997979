import axios from "axios";
import { defineStore } from "pinia";
import { ref, watch } from "vue";

import { components as OpenAPI } from "@/autogen/openapi";
import OpenAPIValues from "@/autogen/openapi.json";
import Urls from "@/autogen/urls";
import { Parameter } from "@/components/Layout/ParameterWidget/lib";
import api from "@/lib/api/base";
import { listSubscribers } from "@/lib/api/subscribers";
import whenNewsletterChanges from "@/lib/whenNewsletterChanges";
import { DEFAULT_PARAMETERS } from "@/screens/Subscribers/default_parameters";
import { Parameter as SubscriberParameter } from "@/screens/Subscribers/parameters";
import {
  LocalStorageKey,
  safeLocalStorageGetItem,
} from "@/store/local_storage";

import { useAggregate, useCreateResource, useUpdateResource } from "./base";
import { constructInitialParameters } from "./utils";

export const useStore = defineStore("subscribers", () => {
  const { fieldToAggregateCount, fieldToGlobalAggregateCount, aggregate } =
    useAggregate(Urls["aggregate"]("subscriber"), DEFAULT_PARAMETERS);

  const resource = ref<OpenAPI["schemas"]["Subscriber"][]>([]);
  const count = ref(0);
  const pages = ref<number[]>([1]);
  const order = ref(
    safeLocalStorageGetItem(LocalStorageKey.SUBSCRIBER_ORDER) || "-date"
  );

  const parameters = ref<Parameter<SubscriberParameter>>(
    constructInitialParameters(
      "/subscribers",
      LocalStorageKey.SUBSCRIBER_PARAMETERS,
      OpenAPIValues.paths["/subscribers"].get.parameters.map((p) => p.name)
    )
  );
  const listing = ref(false);
  const listingAbortController = ref<AbortController | null>(null);

  // This is broken out separately (and called by `SubscribersContainer.mounted`)
  // so we can navigate to the subscribers page with a filter from another page (such as tags)
  // and immediately start filtering the components down. Otherwise, there's no way of telling
  // the store to re-constitute the parameters from the URL.
  const initializeParameters = () => {
    parameters.value = constructInitialParameters(
      "/subscribers",
      LocalStorageKey.SUBSCRIBER_PARAMETERS,
      OpenAPIValues.paths["/subscribers"].get.parameters.map((p) => p.name)
    ) as Parameter<SubscriberParameter>;
  };

  const { updating, update: innerUpdate } = useUpdateResource(
    resource,
    api.path("/subscribers/{id_or_email}").method("patch").create()
  );

  const update = async (
    data: OpenAPI["schemas"]["SubscriberUpdateInput"] & {
      id_or_email: string;
    }
  ) => {
    const response = await innerUpdate(data);
    idToDetailResource.value[data.id_or_email] = response;
  };

  const { creating, create } = useCreateResource(
    resource,
    api.path("/subscribers").method("post").create()
  );

  const list = async () => {
    listing.value = true;
    if (listingAbortController.value) {
      listingAbortController.value.abort();
    }
    const source = new AbortController();
    listingAbortController.value = source;
    try {
      const response = await listSubscribers(
        {
          ...parameters.value,
          page: [pages.value[pages.value.length - 1].toString()],
          ordering: [
            order.value
              .replace("date", "creation_date")
              .replace("status", "subscriber_type")
              .replace("churned", "churn_date")
              .replace("unsubscribed", "unsubscription_date")
              .replace("utm ", "utm_")
              .replace("referrer url", "referrer_url")
              .replace("last_open", "last_open_date")
              .replace("last_click", "last_click_date"),
          ],
        },
        source.signal
      );
      resource.value =
        pages.value[pages.value.length - 1] === 1
          ? response.results
          : [...resource.value, ...response.results];
      count.value = response.count;
      listing.value = false;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      throw error;
    }
  };

  whenNewsletterChanges(async () => {
    list();
  });

  watch(
    () => JSON.stringify(pages.value),
    (pages) => {
      if (pages.length > 0) {
        list();
      }
    }
  );

  watch(
    () => JSON.stringify(parameters.value),
    () => {
      if (pages.value.length > 1) {
        pages.value = [1];
      } else {
        list();
      }
    }
  );

  watch(
    () => order.value,
    (newValue: string) => {
      if (pages.value.length > 1) {
        pages.value = [1];
      } else {
        list();
      }
      localStorage.setItem(LocalStorageKey.SUBSCRIBER_ORDER, newValue);
    }
  );

  // We used to have this as a single `detailResource`, but that meant `pinia-shared-state` would
  // override Email B in Tab 2 with Email A if you navigated from Tab 1 to Tab 2. Maintaining the resources
  // as a mapping solves this issue, at the cost of some annoying data structuring.
  const idToRetrieving = ref<{
    [key: string]: boolean;
  }>({});
  const idToDetailResource = ref<{
    [key: string]: OpenAPI["schemas"]["Subscriber"] | null;
  }>({});
  const retrieve = async (id: string) => {
    idToRetrieving.value[id] = true;
    const response = await api
      .path("/subscribers/{id_or_email}")
      .method("get")
      .create()({
      id_or_email: id,
    });
    idToDetailResource.value[id] = response.data;
    idToRetrieving.value[id] = false;
  };

  return {
    resource,
    count,
    pages,
    order,
    parameters,
    listing,
    list,
    fieldToAggregateCount,
    fieldToGlobalAggregateCount,
    aggregate,
    updating,
    update,
    creating,
    create,
    retrieve,
    idToDetailResource,
    idToRetrieving,
    initializeParameters,
  };
});
