<template>
  <div class="cursor-default">
    <Menu v-slot="{ open }" as="div" class="inline-block text-left max-w-full">
      <MenuButton class="max-w-full">
        <div v-if="$slots['icon']" ref="trigger">
          <slot name="icon" />
        </div>
        <div v-else ref="trigger" :class="button({ variant: props.variant })">
          <div>{{ props.text }}</div>
          <ChevronDownIcon class="size-4" />
        </div>
      </MenuButton>

      <FloatingContainer
        v-if="!isMobile || props.forceFloating || props.static"
        :trigger="trigger"
        :is-static="props.static"
      >
        <Draggable v-if="draggable" v-model="items" item-key="id">
          <template #item="{ element, index }">
            <Item>
              <slot name="row" :item="element" :index="index" />
            </Item>
          </template>
        </Draggable>

        <slot />
      </FloatingContainer>
      <template v-else>
        <DialogContainer :open="open">
          <Draggable v-if="draggable" v-model="items" item-key="id">
            <template #item="{ element, index }">
              <Item>
                <slot name="row" :item="element" :index="index" />
              </Item>
            </template>
          </Draggable>

          <slot />
        </DialogContainer>
      </template>
    </Menu>
  </div>
</template>

<script lang="ts" setup generic="TItem extends { id: string | number }">
import { Menu, MenuButton } from "@headlessui/vue";
import { type VariantProps, cva } from "class-variance-authority";
import { ref } from "vue";
import Draggable from "vuedraggable";

import ChevronDownIcon from "@/icons/heroicons/chevron-down-micro.svg";
import { useMediaQuery } from "@/lib/media_query";

import DialogContainer from "./DialogContainer.vue";
import FloatingContainer from "./FloatingContainer.vue";
import Item from "./Item.vue";

const button = cva(
  "m-0 relative space-x-1 rounded-md text-sm font-medium focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-300 h-auto items-center flex px-2 py-1 no-underline whitespace-nowrap",
  {
    variants: {
      variant: {
        default:
          "bg-gray-100 border border-gray-200 hover:bg-gray-200 transition-colors text-gray-700",
        secondary: "text-gray-400 hover:text-white",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

type ButtonProps = VariantProps<typeof button>;
const props = defineProps<{
  text?: string;
  static?: boolean;
  variant?: ButtonProps["variant"];
  forceFloating?: boolean;
  draggable?: boolean;
}>();

const items = defineModel<TItem[]>("items");

const isMobile = useMediaQuery("(width < 768px)");
const trigger = ref<HTMLElement | null>(null);

defineSlots<{
  default(): void;
  icon(): void;
  row(props: { item: TItem; index: number }): void;
}>();
</script>
