<template>
  <BulkActionModal
    :selection="bulkAction.selection"
    :action="bulkAction.action_type"
    :noun="noun"
    :verb="BULK_ACTION_CONFIGURATION[bulkAction.action_type].verb"
    :icon="icon"
    :list="BULK_ACTION_CONFIGURATION[bulkAction.action_type].list"
    :payload="BULK_ACTION_CONFIGURATION[bulkAction.action_type]?.payload || {}"
    :count="count"
    :header="BULK_ACTION_CONFIGURATION[bulkAction.action_type]?.header"
    :parameters="genericParameters"
    :disabled="disabled"
    :on-success="handleSuccess"
  >
    <template #body="{ parameters, data: subscribers }">
      <div v-if="bulkAction.action_type === 'delete_emails'">
        <div
          v-if="
            bulkAction.selection.mode === 'all' &&
            JSON.stringify(parameters) ===
              JSON.stringify({
                status: [],
                email_type: [],
                source: [],
                automation: [],
              })
          "
        >
          <p class="mb-2">
            You'll be deleting <strong>all</strong> emails, including drafts and
            published emails.
          </p>
        </div>
        <div
          v-else-if="
            bulkAction.selection.mode === 'all' && parameters !== undefined
          "
        >
          <p>You'll be deleting all emails that match the following filters:</p>
          <ParameterTable :parameters="parameters" />
        </div>
        <div
          v-if="
            emails &&
            emails.length === 1 &&
            emails[0].status === 'about_to_send'
          "
        >
          This email is about to be sent out. Are you sure you want to delete
          it?
        </div>
        <div
          v-else-if="
            emails && emails.length === 1 && emails[0].status === 'in_flight'
          "
        >
          This email is currently being sent out. Deleting it will halt that
          process, but some subscribers have already received it.
        </div>
        <div
          v-else-if="
            emails && emails.length === 1 && emails[0].status === 'draft'
          "
        >
          This email is a draft; you won't be able to recover it.
        </div>
        <div v-else-if="emails && emails.length === 1">
          Deleting this email will remove it completely from your newsletter,
          and links pointing to the email will be broken.
        </div>
        <p v-else>
          Deleting these emails will remove them completely from your
          newsletter, and links pointing to the emails will be broken.
        </p>
      </div>
      <div v-else-if="bulkAction.action_type === 'delete_tags'">
        <p v-if="subscribers && subscribers.length === 0">
          Deleting this tag will <strong>not</strong> delete any subscribers or
          emails associated with the tag; they will simply have the tag removed.
        </p>
        <p v-else-if="subscribers && subscribers.length === 1">
          Deleting this tag will <strong>not</strong> delete any subscribers or
          emails associated with the tags; they will simply have the tag
          removed.
        </p>
        <p v-else>
          Deleting these tags will <strong>not</strong> delete any subscribers
          or emails associated with the tags; they will simply have the tag
          removed.
        </p>
      </div>
      <div v-else-if="bulkAction.action_type === 'apply_tags'">
        <CheckboxGroup
          v-model="tag_ids"
          :options="
            tags.map((t) => {
              return {
                id: t.id,
                name: t.name,
              };
            })
          "
        />
      </div>
      <div v-else-if="bulkAction.action_type === 'gift_subscribers'">
        <Setting id="message">
          <template #header>Message</template>
          <template #description
            >You can set a message that will be sent to the subscriber alongside
            their gift.</template
          >
          <LongTextInput v-model="message" />
        </Setting>
        <Setting id="date">
          <template #header>End date</template>
          <template #description
            >You can set a duration for the gift subscription. If you don't set
            a duration, the subscription will last forever.</template
          >
          <div class="-mx-3">
            <DateInput id="date" v-model="date" label="Date" />
          </div>
        </Setting>
      </div>
      <div v-else-if="bulkAction.action_type === 'send_emails'">
        <select
          v-model="email"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
          <option v-for="e in emails" :key="e.id" :value="e">
            {{ e.subject }}
          </option>
        </select>
      </div>
      <div
        v-else-if="
          bulkAction.selection.mode === 'all' && parameters !== undefined
        "
      >
        <p v-if="bulkAction.action_type === 'mark_subscribers_as_not_spammy'">
          You'll be restoring all subscribers that match the following filters:
        </p>
        <p v-if="bulkAction.action_type === 'reactivate_subscribers'">
          You'll be reactivating all subscribers that match the following
          filters:
        </p>
        <p v-if="bulkAction.action_type === 'resubscribe_subscribers'">
          You'll be resubscribing all subscribers that match the following
          filters:
        </p>
        <p v-if="bulkAction.action_type === 'send_reminders'">
          You'll be reminding all subscribers that match the following filters:
        </p>
        <p v-else-if="bulkAction.action_type === 'unsubscribe_subscribers'">
          You'll be unsubscribing all subscribers that match the following
          filters:
        </p>
        <p v-else>
          You'll be deleting all subscribers that match the following filters:
        </p>
        <ParameterTable :parameters="parameters" />
      </div>
      <div
        v-else-if="subscribers && subscribers.length > 0"
        class="text-gray-700 leading-normal space-y-4"
      >
        <p v-if="bulkAction.action_type === 'mark_subscribers_as_not_spammy'">
          Are you sure? Marking
          <span v-if="subscribers.length === 1">this subscriber</span>
          <span v-else>these subscribers</span>
          will restore their original status. If they're marked as spammy again,
          your account will be flagged for suspicious activity; please only use
          this if you're very confident the subscription is legit.
        </p>
        <p v-if="bulkAction.action_type === 'resubscribe_subscribers'">
          Are you sure? Resubscribing
          <span v-if="subscribers.length === 1">this subscriber</span>
          <span v-else>these subscribers</span>
          will add them back to the newsletter. If this subscriber unsubscribes
          again, your account will be flagged for suspicious activity; please
          only use this if you're very confident the unsubscription was in
          error.
        </p>
        <div v-if="bulkAction.action_type === 'reactivate_subscribers'">
          <p>
            Please be absolutely sure your subscriber is aware that you're
            reactivating their subscription!
          </p>
        </div>
        <div v-if="bulkAction.action_type === 'send_reminders'">
          Are you sure? Please make sure you've given this subscriber adequate
          time to respond and read your email; otherwise, you may receive a
          complaint or spam notice from them, harming your deliverability.
        </div>
        <div
          v-if="
            bulkAction.action_type === 'delete_subscribers' ||
            bulkAction.action_type === 'ban_subscribers' ||
            bulkAction.action_type === 'unsubscribe_subscribers'
          "
        >
          <Notice
            v-if="
              subscribers &&
              subscribers.length === 1 &&
              subscribers[0].subscriber_type === 'premium'
            "
            variant="warning"
          >
            Because this subscriber is a premium subscriber, their paid
            subscription will also cease. They will not be refunded; if you want
            to give them a refund, you'll want to do so by going through your
            Stripe dashboard.
          </Notice>
          <p v-if="bulkAction.action_type === 'ban_subscribers'">
            Are you sure? Banning
            <span v-if="subscribers.length === 1">this subscriber</span>
            <span v-else>these subscribers</span>
            will remove them completely from your newsletter, and they will be
            unable to resubscribe.
          </p>
          <p v-if="bulkAction.action_type === 'unsubscribe_subscribers'">
            Are you sure? Unsubscribing
            <span v-if="subscribers.length === 1">this subscriber</span>
            <span v-else>these subscribers</span>
            will remove them completely from your newsletter. They will be able
            to resubscribe if they wish.
          </p>
          <p v-if="bulkAction.action_type === 'delete_subscribers'">
            Deleting
            <span v-if="subscribers.length === 1">this subscriber</span>
            <span v-else>these subscribers</span>
            will remove all record of them from Buttondown. They will be able to
            resubscribe if they wish.
          </p>
        </div>
      </div>
    </template>
  </BulkActionModal>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import type { components as OpenAPI } from "@/autogen/openapi";
import BulkActionModal from "@/components/BulkActionModal/Container.vue";
import ParameterTable from "@/components/Layout/ListView/ParameterTable.vue";
import { list as listEmails } from "@/components/Screens/Emails/BulkActions/lib";
import CheckboxGroup from "@/components/Utilities/Forms/CheckboxGroup.vue";
import { type MappedIconMicro } from "@/icons/icon-mapping-micro";
import api from "@/lib/api/base";
import { listSubscribers } from "@/lib/api/subscribers";
import {
  CreateParams,
  useStore as useBulkActionsStore,
} from "@/store/stores/bulk_actions";
import { useStore as useEmailsStore } from "@/store/stores/emails";
import { useStore as useOpenAPIMirrorStore } from "@/store/stores/openapi-mirror";
import { useStore as useSubscribersStore } from "@/store/stores/subscribers";

import Setting from "../Setting/Setting.vue";
import DateInput from "../Utilities/Forms/DateInput.vue";
import LongTextInput from "../Utilities/Forms/LongTextInput.vue";
import Notice from "../Utilities/Notice.vue";

const props = defineProps<{
  bulkAction: CreateParams;
}>();

const bulkActionsStore = useBulkActionsStore();
const emailsStore = useEmailsStore();
const subscribersStore = useSubscribersStore();

// These represent possible payloads for the bulk action.
const email = ref<OpenAPI["schemas"]["Email"] | null>(null);
const tag_ids = ref<string[]>([]);
const message = ref("");
const date = ref<string | null>(null);

const emails = computed(() =>
  emailsStore.resource.filter(
    (email) => email.status === "sent" || email.status === "imported"
  )
);

const store = useOpenAPIMirrorStore();
const tags = computed(() => store["/tags"].value.resource);
const emailCount = computed(() => emailsStore.count);
const tagsCount = computed(() => store["/tags"].value.count);
const emailParameters = computed(() => emailsStore.parameters);
const subscriberCount = computed(() => subscribersStore.count);
const subscriberParameters = computed(() => subscribersStore.parameters);

const count = computed(() => {
  return (
    BULK_ACTION_CONFIGURATION.value[props.bulkAction.action_type]?.count ||
    subscriberCount.value
  );
});

const genericParameters = computed(() => {
  return (
    BULK_ACTION_CONFIGURATION.value[props.bulkAction.action_type]?.parameters ||
    subscriberParameters.value
  );
});

type BulkActionConfiguration = {
  noun: string;
  icon: MappedIconMicro;
  verb: string;
  label: string;
  payload?: object;
  parameters?: any;
  count?: number;
  disabled?: () => boolean;
  handleSuccess?: () => void;
  header?: (o: any) => string;
  list?: (ids: string[]) => Promise<any>;
};

const BULK_ACTION_CONFIGURATION = computed<{
  [key in OpenAPI["schemas"]["BulkActionType"]]: BulkActionConfiguration;
}>(() => ({
  apply_metadata: {
    noun: "subscriber",
    icon: "code-bracket",
    verb: "Apply",
    label: "Applying metadata",
  },
  mark_subscribers_as_not_spammy: {
    noun: "subscriber",
    verb: "Vouch",
    icon: "arrow-uturn-right",
    label: "Restoring subscriber",
  },
  update_email_types: {
    noun: "email",
    verb: "Update",
    icon: "eye",
    label: "Updating email type",
  },
  replay_events: {
    noun: "event",
    verb: "Replay",
    icon: "arrow-path-rounded-square",
    label: "Replaying event",
  },
  update_survey_statuses: {
    noun: "survey",
    verb: "Update",
    icon: "eye",
    label: "Updating survey status",
  },
  send_emails: {
    noun: "email",
    icon: "envelope",
    disabled: () => email.value === null,
    payload: { email_id: email.value?.id },
    verb: "Email",
    label: "Sending email",
  },
  apply_tags: {
    noun: "subscriber",
    icon: "tag",
    disabled: () => tag_ids.value.length === 0,
    payload: { tag_ids: tag_ids.value, action: props.bulkAction.action },
    verb:
      props.bulkAction.action === "remove"
        ? "Remove tags from"
        : "Apply tags to",
    label: "Applying tag",
  },
  delete_tags: {
    noun: "tag",
    header: (tag: OpenAPI["schemas"]["Tag"]) => tag.name,
    count: tagsCount.value,
    icon: "trash",
    handleSuccess: () => {
      store["/tags"].value.list();
    },
    verb: "Delete",
    list: () =>
      api
        .path("/tags")
        .method("get")
        .create()({})
        .then((response) => {
          return response.data.results.filter((tag) =>
            props.bulkAction.ids.includes(tag.id)
          );
        }),
    label: "Deleting tag",
  },
  delete_comments: {
    noun: "comment",
    icon: "trash",
    header: (comment: OpenAPI["schemas"]["Comment"]) => comment.id,
    handleSuccess: () => {
      store["/comments"].value.list();
    },
    verb: "Delete",
    label: "Deleting comment",
  },
  delete_emails: {
    noun: "email",
    icon: "trash",
    header: (email: OpenAPI["schemas"]["Email"]) =>
      email.subject || "(no subject)",
    parameters: emailParameters.value,
    count: emailCount.value,
    handleSuccess: () => {
      emailsStore.list();
      emailsStore.aggregateEmails(emailParameters.value);
      emailsStore.aggregateGlobal();
    },
    verb: "Delete",
    list: listEmails,
    label: "Deleting email",
  },
  delete_surveys: {
    noun: "survey",
    icon: "trash",
    handleSuccess: () => {
      store["/surveys"].value.list();
    },
    verb: "Delete",
    label: "Deleting survey",
  },
  ban_subscribers: {
    noun: "subscriber",
    icon: "trash",
    header: (subscriber: OpenAPI["schemas"]["Subscriber"]) =>
      subscriber.email_address,
    verb: "Ban",
    list: (ids: string[]) =>
      listSubscribers({ ids }).then((response) => response.results),
    label: "Banning subscriber",
  },
  delete_subscribers: {
    noun: "subscriber",
    icon: "trash",
    header: (subscriber: OpenAPI["schemas"]["Subscriber"]) =>
      subscriber.email_address,
    verb: "Delete",
    list: (ids: string[]) =>
      listSubscribers({ ids }).then((response) => response.results),
    label: "Deleting subscriber",
  },
  send_reminders: {
    noun: "subscriber",
    icon: "bell",
    header: (subscriber: OpenAPI["schemas"]["Subscriber"]) =>
      subscriber.email_address,
    verb: "Remind",
    label: "Sending reminder",
  },
  unsubscribe_subscribers: {
    noun: "subscriber",
    icon: "user-minus",
    header: (subscriber: OpenAPI["schemas"]["Subscriber"]) =>
      subscriber.email_address,
    verb: "Unsubscribe",
    list: (ids: string[]) =>
      listSubscribers({ ids }).then((response) => response.results),
    label: "Unsubscribing subscriber",
  },
  reactivate_subscribers: {
    noun: "subscriber",
    icon: "arrow-uturn-right",
    header: (subscriber: OpenAPI["schemas"]["Subscriber"]) =>
      subscriber.email_address,
    verb: "Reactivate",
    list: (ids: string[]) =>
      listSubscribers({ ids }).then((response) => response.results),
    label: "Reactivating subscriber",
  },
  resubscribe_subscribers: {
    noun: "subscriber",
    icon: "arrow-uturn-left",
    header: (subscriber: OpenAPI["schemas"]["Subscriber"]) =>
      subscriber.email_address,
    verb: "Resubscribe",
    list: (ids: string[]) =>
      listSubscribers({ ids }).then((response) => response.results),
    label: "Resubscribing subscriber",
  },
  gift_subscribers: {
    noun: "subscriber",
    icon: "gift",
    verb: "Gift",
    label: "Granting gift subscription",
    header: (subscriber: OpenAPI["schemas"]["Subscriber"]) =>
      subscriber.email_address,
    payload:
      date.value === null
        ? {
            message: message.value,
          }
        : { message: message.value, date: date.value },
    list: (ids: string[]) =>
      listSubscribers({ ids }).then((response) => response.results),
  },
}));

const icon = computed(
  () =>
    BULK_ACTION_CONFIGURATION.value[props.bulkAction.action_type]?.icon ||
    "question-mark-circle"
);

const noun = computed(
  () =>
    BULK_ACTION_CONFIGURATION.value[props.bulkAction.action_type]?.noun ||
    "subscriber"
);

const disabled = computed(() => {
  return (
    BULK_ACTION_CONFIGURATION.value[
      props.bulkAction.action_type
    ]?.disabled?.() || false
  );
});

const handleSuccess = computed(() => {
  return () => {
    bulkActionsStore.setDraft(null);
    return (
      BULK_ACTION_CONFIGURATION.value[props.bulkAction.action_type]
        ?.handleSuccess ||
      (() => {
        subscribersStore.list();
      })
    )();
  };
});
</script>
