import axios from "axios";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

import { components as OpenAPI } from "@/autogen/openapi";
import Urls from "@/autogen/urls";
import { Parameter } from "@/components/Layout/ParameterWidget/lib";
import { DEFAULT_PARAMETERS } from "@/components/Screens/Emails/default_parameters";
import { ORDER_TO_SERVER_SIDE_ORDER } from "@/components/Screens/Emails/orders";
import api from "@/lib/api/base";
import { listEmails } from "@/lib/api/emails";
import whenNewsletterChanges from "@/lib/whenNewsletterChanges";
import {
    LocalStorageKey,
    safeLocalStorageGetItem,
} from "@/store/local_storage";
import {
    PARAMETERS as EMAIL_PARAMETERS,
    Parameter as EmailParameter,
} from "@/types/email";

import { useAggregate, useCreateResource, useUpdateResource } from "./base";
import { constructInitialParameters } from "./utils";

export const useStore = defineStore("emails", () => {
  const {
    aggregate: aggregateEmails,
    fieldToAggregateCount,
    fieldToGlobalAggregateCount,
    aggregateGlobal,
  } = useAggregate<string>(
    Urls["aggregate"]("email"),
    DEFAULT_PARAMETERS[0].parameters
  );

  const resource = ref<OpenAPI["schemas"]["Email"][]>([]);
  const count = ref(0);
  const pages = ref<number[]>([1]);
  const order = ref(
    safeLocalStorageGetItem(LocalStorageKey.EMAIL_ORDER) || "-created"
  );
  const parameters = ref<Parameter<EmailParameter>>(
    constructInitialParameters(
      "/emails",
      LocalStorageKey.EMAIL_PARAMETERS,
      EMAIL_PARAMETERS
    )
  );
  const listing = ref(false);

  const list = async () => {
    listing.value = true;
    try {
      const mungedOrder = Object.entries(ORDER_TO_SERVER_SIDE_ORDER).reduce(
        (acc, [key, value]) => {
          return acc.replace(key, value);
        },
        order.value
      );
      const response = await listEmails({
        page: [pages.value[pages.value.length - 1].toString()],
        ordering: [mungedOrder],
        ...parameters.value,
      });
      resource.value =
        pages.value[pages.value.length - 1] === 1
          ? response.results
          : [...resource.value, ...response.results];
      count.value = response.count;
      listing.value = false;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      throw error;
    }
  };

  whenNewsletterChanges(() => {
    list();
  });

  watch(
    () => JSON.stringify(pages.value),
    (pages) => {
      if (pages.length > 0) {
        list();
      }
    }
  );

  watch(
    () => JSON.stringify(parameters.value),
    () => {
      if (pages.value.length > 1) {
        pages.value = [1];
      } else {
        list();
      }
    }
  );

  watch(
    () => order.value,
    (newValue: string) => {
      if (pages.value.length > 1) {
        pages.value = [1];
      } else {
        list();
      }
      localStorage.setItem(LocalStorageKey.EMAIL_ORDER, newValue);
    }
  );

  const {
    updating,
    update: innerUpdate,
    updateAbortController,
  } = useUpdateResource(
    resource,
    api.path("/emails/{id}").method("patch").create()
  );

  const { creating, create } = useCreateResource(
    resource,
    api.path("/emails").method("post").create()
  );

  // We used to have this as a single `detailResource`, but that meant `pinia-shared-state` would
  // override Email B in Tab 2 with Email A if you navigated from Tab 1 to Tab 2. Maintaining the resources
  // as a mapping solves this issue, at the cost of some annoying data structuring.
  const idToRetrieving = ref<{
    [key: string]: boolean;
  }>({});
  const idToDetailResource = ref<{
    [key: string]: OpenAPI["schemas"]["Email"];
  }>({});
  const retrieve = async (id: string) => {
    idToRetrieving.value[id] = true;
    const response = await api.path("/emails/{id}").method("get").create()({
      id,
    });
    idToDetailResource.value[id] = response.data;
    idToRetrieving.value[id] = false;
  };

  const update = async (
    data: OpenAPI["schemas"]["EmailUpdateInput"] & {
      id: string;
    }
  ) => {
    const response = await innerUpdate(data);
    idToDetailResource.value[data.id] = response;
  };

  const latestEmail = computed(() => {
    if (resource.value.length === 0) {
      return null;
    }

    return resource.value
      .filter((email) => email.body !== null && email.body !== "")
      .sort((a, b) => (b.publish_date as any) - (a.publish_date as any))[0];
  });

  return {
    fieldToAggregateCount,
    aggregateEmails,
    idToRetrieving,
    resource,
    count,
    pages,
    order,
    parameters,
    listing,
    updating,
    update,
    list,
    idToDetailResource,
    creating,
    create,
    updateAbortController,
    fieldToGlobalAggregateCount,
    retrieve,
    latestEmail,
    aggregateGlobal,
  };
},
{
  // @ts-ignore
  // This is via `pinia-shared-state`, and I don't care enough to make it TS-happy.
  share: {
    // If you have `/emails/foo` open, then `idToDetailResource` has a value of `foo: { id: foo, subject: Foo, }`.
    // If you then open `/emails` in another tab, that gets _wiped out_, and along with it your state in `/emails/foo` breaks.
    // This is very bad. (In fact, as I write this I suspect there are _other_ big footguns of this genre, and we should probably
    // either switch to:
    // 1. Not using `pinia-shared-state` at all
    // 2. Shifting to an allowlist model rather than a denylist model
    // 3. Rolling our own version (the footprint is actually tiny.)
    omit: ["idToDetailResource"],
  },
});
