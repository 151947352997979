<template>
  <div
    v-if="canRead"
    :class="{
      'px-3 py-1 hover:bg-gray-300 group rounded-xl lg:text-sm font-500 border lg:border-0 text-lg font-medium': true,
      'bg-gray-300': active,
    }"
  >
    <RouterLink
      v-if="route"
      :to="route"
      :class="{
        'text-gray-700 no-underline': true,
        'text-black': active,
        '!text-blue-500': emphasize,
      }"
    >
      <div class="flex items-center font-400 text-md space-x-2">
        <component :is="icon" class="size-4" />
        <div class="flex-1">{{ title }}</div>
        <div
          v-if="number"
          :class="{
            'text-[10px] px-2 rounded-full font-bold': true,
            'bg-gray-300 group-hover:bg-gray-500 group-hover:text-white':
              !active,
            'bg-gray-500 text-white': active,
          }"
        >
          {{ number }}
        </div>
      </div>
    </RouterLink>
    <a
      v-else
      :href="url"
      :class="{
        'text-gray-700 no-underline': true,
        'cursor-default': !url,
        'text-black': active,
      }"
    >
      <div class="flex items-center font-400 text-md space-x-2">
        <component :is="icon" class="size-4" />
        <div>{{ title }}</div>
      </div>
    </a>
  </div>
</template>

<script lang="ts" setup>
import { type Component, computed } from "vue";
import { useRouter } from "vue-router/auto";

import { RouteLocation } from "@/router/types";
import { useStore as useScaffoldingStore } from "@/store/stores/scaffolding";
import { Permission } from "@/types/account";

const props = defineProps<{
  icon: Component;
  title: string;
  number?: number;
  route?: RouteLocation;
  url?: string;
  permission?: keyof Permission["permissions"];
  emphasize?: boolean;
}>();

const store = useScaffoldingStore();
const router = useRouter();

const currentPermissions = computed(() => {
  return store.currentPermissions;
});
const active = computed(() => {
  // @ts-ignore
  const name = props.route?.name as string | undefined;
  if (!name) {
    return false;
  }
  return router.currentRoute.value.path.startsWith(name as string);
});
const canRead = computed(() => {
  if (!props.permission) {
    return true;
  }

  return currentPermissions.value[props.permission] !== undefined;
});
</script>
