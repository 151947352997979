<template>
  <div>
    <div
      v-bind="$attrs"
      class="space-y-2 bg-gray-50 h-screen flex flex-col lg:w-[200px] border-gray-300 border border-solid border-r-0 lg:border-r border-l-0 border-y-0 border-box"
    >
      <div
        class="flex items-center border-gray-300 border border-solid border-x-0 border-t-0 p-2"
      >
        <Header :newsletter="newsletter" :permissions="account.permissions" />
        <button
          class="text-sm hover:bg-gray-200 rounded-lg p-[2px] -m-[2px] mr-3 bg-gray-100"
          aria-label="Toggle sidebar"
          @click="toggleSidebar"
        >
          <Bars3Icon class="size-5" />
        </button>
      </div>
      <div class="p-2 pb-4 flex flex-col space-y-2 flex-1 overflow-y-scroll">
        <SearchButton @click="showSearch = true" />
        <template v-for="item in activeNavigation" :key="item.icon">
          <NavigationItem
            v-if="item.route && item.title && item.icon"
            :route="item.route"
            :icon="item.icon"
            :title="item.title"
        /></template>
        <NavigationItem
          :route="{
            name: '/settings/basics',
          }"
          :icon="CogIcon"
          title="Settings"
        />
        <div
          v-if="showingSettings"
          :class="{
            'bg-gray-200/50 -mx-2 px-2 transition-all border-t border-t-gray-300 border-b border-b-gray-300': true,
          }"
        >
          <Sidebar />
        </div>
        <div class="flex-1" />
        <NavigationItem
          v-if="
            account.billing_plan === 'free' &&
            account.billing_type === 'V2' &&
            newsletter.owning_account_billing_plan === 'free'
          "
          :route="{
            name: '/settings/billing/pricing',
          }"
          :icon="BoltIcon"
          title="Upgrade"
          emphasize
          @click="metrics.increment('upgrade_clicked', 1)"
        />
        <NavigationItem
          url="https://docs.buttondown.com"
          :icon="BookOpenIcon"
          title="Documentation"
        />
        <NavigationItem
          v-if="!isDemo"
          :icon="ArrowRightStartOnRectangleIcon"
          title="Log out"
          @click="showLogout = true"
        />
      </div>
    </div>

    <SearchModal v-if="showSearch" @close="showSearch = false" />
    <Dialog v-if="showLogout" @close="showLogout = false">
      <template #header> Log out </template>
      <template #footer>
        <ActionButton
          text="Log out"
          icon="arrow-right-start-on-rectangle"
          variant="primary"
          @click="logout"
        />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router/auto";

import ActionButton from "@/components/Utilities/ActionButton.vue";
import Dialog from "@/design_system/Dialog/Component.vue";
import ArrowRightStartOnRectangleIcon from "@/icons/heroicons/arrow-right-start-on-rectangle-micro.svg";
import Bars3Icon from "@/icons/heroicons/bars-3-outline.svg";
import BoltIcon from "@/icons/heroicons/bolt-micro.svg";
import BookOpenIcon from "@/icons/heroicons/book-open-micro.svg";
import CogIcon from "@/icons/heroicons/cog-6-tooth-micro.svg";
import metrics from "@/lib/metrics";
import Sidebar from "@/screens/Settings/Sidebar.vue";
import { useStore } from "@/store/stores/scaffolding";
import { Account } from "@/types/account";
import { Newsletter } from "@/types/newsletter";
import { notify } from "@/utils";

import SearchModal from "../SearchModal/Container.vue";
import Header from "./Header/Component.vue";
import { determineActiveNavigation } from "./lib";
import NavigationItem from "./NavigationItem.vue";
import SearchButton from "./SearchButton.vue";

const props = defineProps<{
  account: Account;
  newsletter: Newsletter;
}>();

const store = useStore();
const router = useRouter();
const toggleSidebar = store.toggleSidebar;
const showingSettings = computed(() =>
  router.currentRoute.value.path.startsWith("/settings")
);
const currentPermissions = computed(() => store.currentPermissions);
const isDemo = computed(() => store.isDemo);

const showSearch = ref(false);
const showLogout = ref(false);

const activeNavigation = computed(() => {
  return determineActiveNavigation(currentPermissions.value, props.newsletter);
});

const logout = async () => {
  await store.logout();
  router.push({
    name: "/login",
  });
  notify("You're logged out.  Have a great day!", "success");
};
</script>
