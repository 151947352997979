<template>
  <div class="flex-1" style="max-width: calc(100% - 30px)">
    <Dropdown.Button v-model:items="sortedNewsletters" draggable>
      <template #icon>
        <div
          class="font-bold text-center flex space-x-2 px-3 items-center text-sm overflow-hidden hover:bg-gray-200 py-2 rounded-xl cursor-pointer"
        >
          <NewsletterIcon :newsletter="props.newsletter" />
          <div
            class="whitespace-nowrap overflow-x-clip overflow-ellipsis flex-1 truncate text-left"
          >
            {{ props.newsletter.name || props.newsletter.username }}
          </div>
        </div>
      </template>

      <template #row="{ item }">
        <List.MenuItem
          icon="eye"
          :text="item.name || item.username"
          :active="item.id === props.newsletter.id"
          @click="switchNewsletter(item)"
        >
          <template #icon>
            <NewsletterIcon :newsletter="item" />
          </template>
        </List.MenuItem>
      </template>

      <List.MenuItem
        icon="plus"
        text="Add Newsletter"
        :to="{ name: '/settings/danger-zone/add-newsletter' }"
      />
    </Dropdown.Button>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";

import Dropdown from "@/design_system/Dropdown";
import List from "@/design_system/View/List";
import { LocalStorageKey } from "@/store/local_storage";
import { useLocalStorageBackedRef } from "@/store/stores/base";
import { useStore as useScaffoldingStore } from "@/store/stores/scaffolding";
import { Permission } from "@/types/account";
import { Newsletter } from "@/types/newsletter";

import NewsletterIcon from "./NewsletterIcon.vue";

const store = useScaffoldingStore();

const props = defineProps({
  newsletter: {
    type: Object as PropType<Newsletter>,
    required: true,
  },
  permissions: {
    type: Array as PropType<Permission[]>,
    required: true,
  },
});

const sortedIds = useLocalStorageBackedRef<string[]>(
  LocalStorageKey.NEWSLETTERS_ORDER,
  [],
  (ordering) => JSON.stringify(ordering),
  (raw) => JSON.parse(raw || "[]")
);

const sortedNewsletters = computed({
  get() {
    const newsletters = props.permissions.map((p) => p.newsletter);

    const map = new Map(sortedIds.value.map((id, index) => [id, index]));
    const boundary = map.size;

    // Mutate the array as we've already cloned it from mapping
    newsletters.sort((a, b) => {
      return (map.get(a.id) ?? boundary) - (map.get(b.id) ?? boundary);
    });

    return newsletters;
  },
  set(newsletters) {
    sortedIds.value = newsletters.map((n) => n.id);
  },
});

const switchNewsletter = store.switchNewsletter;
</script>
