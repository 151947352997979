import {
  arrow,
  autoUpdate,
  computePosition,
  flip,
  offset,
  shift,
  size,
} from "@floating-ui/dom";
import { Placement } from "@floating-ui/utils";
import { onMounted, ref, watchEffect } from "vue";

export interface FloatingOptions {
  placement?: Placement;
  boundaryPadding?: number;
  distance?: number;
  skidding?: number;
  flip?: boolean;
  matchWidth?: boolean;
  arrow?: {
    selector: string;
    padding?: number;
  };
}

export function useFloating(options: FloatingOptions) {
  const reference = ref<HTMLElement | null>(null);
  const popper = ref<HTMLElement | null>(null);

  onMounted(() => {
    watchEffect((onInvalidate) => {
      const referenceEl = reference.value;
      const popperEl = popper.value;

      if (!(referenceEl instanceof HTMLElement)) return;
      if (!(popperEl instanceof HTMLElement)) return;

      let destroyed = false;

      const update = () => {
        if (destroyed) {
          return;
        }

        let arrowEl: HTMLElement | null | undefined;
        if (options.arrow) {
          arrowEl = popperEl.querySelector(
            options.arrow.selector
          ) as HTMLElement;
        }

        const promise = computePosition(referenceEl, popperEl, {
          placement: options.placement,
          middleware: [
            options.flip &&
              flip({
                padding: options.boundaryPadding ?? 10,
                crossAxis: false,
              }),
            shift({
              padding: options.boundaryPadding ?? 10,
            }),
            options.distance !== undefined &&
              offset({
                // Nullish coalescing here because Floating UI uses spreading to
                // set default value, just having this property alone means that
                // setting `undefined` doesn't work as it should
                mainAxis: options.distance,
                crossAxis: options.skidding ?? 0,
              }),
            options.matchWidth &&
              size({
                apply({ rects, elements }) {
                  Object.assign(elements.floating.style, {
                    width: `${rects.reference.width}px`,
                  });
                },
              }),
            arrowEl &&
              arrow({
                element: arrowEl,
                padding: options.arrow?.padding,
              }),
          ],
        });

        promise.then((result) => {
          if (destroyed) {
            return;
          }

          Object.assign(popperEl.style, {
            position: result.strategy,
            left: `${result.x}px`,
            top: `${result.y}px`,
          });

          const middlewareData = result.middlewareData;
          if (arrowEl && middlewareData.arrow) {
            // https://floating-ui.com/docs/arrow#visualization
            const side = result.placement.split("-")[0];
            const staticSide = {
              top: "bottom",
              right: "left",
              bottom: "top",
              left: "right",
            }[side] as "bottom";

            const arrowData = middlewareData.arrow;

            Object.assign(arrowEl.style, {
              left: arrowData.x != null ? `${arrowData.x}px` : "",
              top: arrowData.y != null ? `${arrowData.y}px` : "",
              [staticSide]: `${-arrowEl.offsetWidth / 2}px`,
            });
          }
        });
      };

      const cleanup = autoUpdate(referenceEl, popperEl, update);

      onInvalidate(() => {
        destroyed = true;
        cleanup();
      });
    });
  });

  return [reference, popper] as const;
}
