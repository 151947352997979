import { RouteConfiguration, SEARCH_CONTEXT } from "@/search/routes";
import { Permission } from "@/types/account";
import { Newsletter } from "@/types/newsletter";

const determineActiveNavigation = (
  permissions: Permission["permissions"],
  newsletter: Newsletter
): RouteConfiguration[] => {
  return [
    SEARCH_CONTEXT["/subscribers"],
    SEARCH_CONTEXT["/emails"],
    SEARCH_CONTEXT["/tags"],
    SEARCH_CONTEXT["/automations"],
    SEARCH_CONTEXT["/comments"],
    SEARCH_CONTEXT["/surveys"],
    SEARCH_CONTEXT["/webhooks"],
    SEARCH_CONTEXT["/feeds"],
    SEARCH_CONTEXT["/sponsorships"],
    SEARCH_CONTEXT["/analytics"],
    SEARCH_CONTEXT["/requests"],
    SEARCH_CONTEXT["/events"],
  ].filter(
    (item) => item.hide === undefined || !item.hide(permissions, newsletter)
  );
};

export { determineActiveNavigation };
