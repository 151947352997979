<template>
  <div
    :class="{
      'relative flex flex-1': variant === 'setting',
      'flex-1 flex flex-col': variant === 'view',
    }"
  >
    <div
      :class="{
        'flex-1 flex flex-col': true,
        'border border-gray-200 border-solid rounded bg-gray-50 py-4':
          variant !== 'view',
      }"
    >
      <div
        :class="{
          'min-h-[48px] flex-1': (items && items.length) || pending,
          relative: pending,
          'overflow-x-scroll': true,
        }"
      >
        <table
          v-if="!error && (isStatic || (items && items.length))"
          class="table-fixed lg:table-auto border-collapse w-full"
        >
          <thead
            :class="{
              'hidden lg:table-header-group': !alwaysShowHeader,
              'table-header-group': alwaysShowHeader,
            }"
          >
            <tr v-if="columns">
              <th
                v-for="(column, i) in columns"
                :key="column"
                :class="{
                  'text-left text-xs font-bold pb-2 pr-4 text-gray-500 uppercase tracking-wider whitespace-nowrap': true,
                  // Remove right-padding if it's the last 'real' column and there's an action after.
                  'text-right [&:not(:last-child)]:!pr-0':
                    columns.filter((c) => c != 'LEFT_SPACER_SENTINEL').length >
                      1 &&
                    i + 2 === columns.length &&
                    columns[columns.length - 1] === 'RIGHT_SPACER_SENTINEL',
                  // Similar behavior for non-view variants.
                  'text-right': i === columns.length - 1 && variant !== 'view',
                  // Add left-padding if it's the first 'real' column.
                  'pl-4': i === 0,
                }"
              >
                <slot name="header" :item="column" :index="i">
                  <div
                    :class="{
                      'cursor-pointer':
                        orders?.includes(column) || clientsideSorting,
                    }"
                    @click="
                      () => {
                        orders?.includes(column)
                          ? $emit(
                              'update:order',
                              order === column ? `-${column}` : column
                            )
                          : clientsideSorting
                          ? (clientsideOrder =
                              clientsideOrder === column
                                ? `-${column}`
                                : column)
                          : null;
                      }
                    "
                  >
                    {{ mungeColumn(column) }}
                    <span
                      v-if="
                        column !== 'LEFT_SPACER_SENTINEL' &&
                        column !== 'RIGHT_SPACER_SENTINEL' &&
                        (order === column || clientsideOrder === column)
                      "
                      >⬆</span
                    >
                    <span
                      v-if="
                        column !== 'LEFT_SPACER_SENTINEL' &&
                        column !== 'RIGHT_SPACER_SENTINEL' &&
                        (order === `-${column}` ||
                          clientsideOrder === `-${column}`)
                      "
                      >⬇</span
                    >
                  </div>
                </slot>
              </th>
            </tr>
          </thead>

          <tbody v-if="isStatic">
            <slot />
          </tbody>
          <tbody v-else-if="!draggable">
            <template v-for="(item, i) in draggableItems" :key="item.id">
              <slot
                name="row"
                :item="item"
                :index="i"
                :parameters="parameters"
                :selection="selection"
                :handle-model-clicked="handleModelClicked"
                :newsletter="newsletter"
              />
            </template>
          </tbody>
          <draggable
            v-else
            v-model="draggableItems"
            item-key="id"
            tag="tbody"
            ghost-class="opacity-50"
            :disabled="!draggable"
          >
            <template #item="{ element, index }">
              <List.Row
                :draggable="variant === 'view'"
                :selectable="variant === 'view'"
                :selection="selection"
                :item="element"
                :handle-model-clicked="handleModelClicked"
              >
                <slot
                  name="row"
                  :item="element"
                  :index="index"
                  :parameters="parameters"
                  :selection="selection"
                  :handle-model-clicked="handleModelClicked"
                  :newsletter="newsletter"
                />
                <template v-if="$slots['row-actions']" #actions>
                  <slot name="row-actions" :item="element" />
                </template>
              </List.Row>
            </template>
          </draggable>
          <slot name="footer"></slot>
        </table>
        <div v-if="error" class="flex items-center gap-1">
          <XCircleIcon class="size-4 text-red-600 inline" />
          <span>{{ error }}</span>
        </div>
        <div
          v-if="pending"
          :class="{
            'flex absolute top-0 left-0 right-0 bottom-0 items-center z-10': true,
            'bg-white/50': items && items.length,
          }"
        >
          <div
            :class="{
              'mx-auto text-center space-y-2 text-gray-400': true,
              'text-2xl': variant === 'view',
              'py-8': variant === 'setting',
            }"
          >
            <PendingIndicator pending />
            <div>Loading</div>
          </div>
        </div>
      </div>

      <div
        v-if="
          $slots['empty-state'] &&
          !pending &&
          !error &&
          items &&
          items.length === 0
        "
        class="flex flex-1 items-center justify-center"
      >
        <div :class="{ 'py-4': variant === 'setting' }">
          <slot name="empty-state" />
        </div>
      </div>

      <div v-if="$slots.action" class="pt-4 px-4">
        <slot name="action"></slot>
      </div>
    </div>

    <slot name="postscript" />
  </div>
</template>

<script lang="ts" setup generic="Model">
import { computed, ref } from "vue";
import Draggable from "vuedraggable";

import { Selection } from "@/components/Layout/ListView/lib";
import { Parameter } from "@/components/Layout/ParameterWidget/lib";
import PendingIndicator from "@/components/Utilities/PendingIndicator.vue";
import List from "@/design_system/View/List";
import XCircleIcon from "@/icons/heroicons/x-circle-micro.svg";
import { Newsletter } from "@/types/newsletter";

export type HandleModelClicked = (item: any, event: MouseEvent) => void;
export type DataTableRowProps<M> = {
  item: M;
  index: number;
  parameters?: Parameter<string>;
  selection?: Selection<M>;
  handleModelClicked?: HandleModelClicked;
  newsletter?: Newsletter;
};

const props = defineProps<{
  columns?: string[];
  selection?: Selection<Model>;
  items?: Model[];
  variant?: "view" | "setting";
  pending?: boolean;
  error?: string;
  isStatic?: boolean;
  alwaysShowHeader?: boolean;
  newsletter?: Newsletter;
  handleModelClicked?: HandleModelClicked;
  draggable?: boolean;
  parameters?: Parameter<string>;
  orders?: readonly string[];
  order?: string;
  clientsideSorting?:
    | {
        [key in string]: (a: any) => any;
      };
}>();

defineSlots<{
  header(props: { item: string; index: number }): void;
  footer(props: {}): void;
  postscript(props: {}): void;
  "empty-state"(props: {}): void;
  default(props: {}): void;
  action(props: {}): void;
  row(props: DataTableRowProps<Model>): void;
  "row-actions"(props: { item: Model }): void;
}>();

const emit = defineEmits(["update:items", "update:order"]);

const draggableItems = computed({
  get() {
    if (props.clientsideSorting === undefined || clientsideOrder.value === "") {
      return props.items;
    }

    const order = clientsideOrder.value.startsWith("-") ? -1 : 1;
    const column = clientsideOrder.value.replace("-", "");
    return (props.items || []).sort((a, b) => {
      const aVal = props.clientsideSorting?.[column](a);
      const bVal = props.clientsideSorting?.[column](b);
      return (aVal > bVal ? 1 : -1) * order;
    });
  },
  set(value) {
    emit("update:items", value);
  },
});

const clientsideOrder = ref("");

const mungeColumn = (column: string) => {
  if (column === "page_views__lifetime") {
    return "Views (all)";
  }
  if (column === "page_views__30") {
    return "Views (past 30)";
  }
  if (column === "page_views__7") {
    return "Views (past 7)";
  }
  if (column === "LEFT_SPACER_SENTINEL") {
    return "";
  }
  if (column === "RIGHT_SPACER_SENTINEL") {
    return "";
  }
  return column.replaceAll("_", " ");
};
</script>
