import "vite/modulepreload-polyfill";
import "./index.css";

import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import axios from "axios";
import Noty from "noty";
import { createPinia } from "pinia";
import { PiniaSharedState } from "pinia-shared-state";
import { setupCalendar } from "v-calendar";
import { createApp } from "vue";

import Urls from "./autogen/urls";
import router from "./router";
import App from "./scaffolding/App.vue";
import { useStore as useScaffoldingStore } from "./store/stores/scaffolding";
import { formatDate } from "./utils";

Noty.overrideDefaults({
  theme: "bootstrap-v4",
  layout: "topCenter",
  timeout: 2000,
});

const pinia = createPinia();
pinia.use(
  PiniaSharedState({
    enable: true,
    initialize: false,
    type: "localstorage",
  })
);

const app = createApp(App);

// Allow accessing URLs in all components.
app.config.globalProperties.Urls = Urls;

// Register filters.
app.config.globalProperties.$filters = { formatDate };

// Fix CSRF.
axios.defaults.xsrfHeaderName = "X-CSRFToken";

// Register third-party extensions.
app.use(setupCalendar, {});
app.use(pinia);
app.use(router);

// Add API token to all outgoing requests.
axios.interceptors.request.use(
  (config) => {
    const scaffoldingStore = useScaffoldingStore();
    if (!scaffoldingStore.newsletter) {
      return config;
    }
    const token = scaffoldingStore.newsletter.api_key;
    if (token != null) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Enable Sentry.
// Via https://github.com/getsentry/sentry-javascript/issues/2925.
Sentry.init({
  app,
  dsn: "https://8022d4e4bf8f49c6bc1db715c70ec0ce@o97520.ingest.sentry.io/213667",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "127.0.0.1",
        "buttondown.email",
        "buttondown.com",
        /^\//,
      ],
    }),
    Sentry.metrics.metricsAggregatorIntegration(),
  ],

  // 2024-04-23: We will almost certainly need to bump this down or remove it entirely,
  // but let's see how replays work!
  replaysSessionSampleRate: 1.0,

  tracesSampleRate: 1.0,
  logErrors: true,
  trackComponents: true,
  ignoreErrors: [
    "Non-Error",
    "Can't find variable: pktAnnotationHighlighter",
    "ChunkLoadError",

    // We abort a lot of things to de-dupe requests, so this is fine.
    "AbortError",
  ],
  initialScope: {
    account: {
      username: account.username, // eslint-disable-line
    },
  },
});

app.mount("#app-scaffold");

// Via https://vitejs.dev/guide/build#load-error-handling,
// which itself is via https://github.com/vitejs/vite/issues/11804#issuecomment-2055217776,
// which is meant to (hopefully!) solve https://github.com/buttondown/roadmap/issues/2593.
window.addEventListener("vite:preloadError", () => {
  window.alert("There was an error loading the page. Please try again.");
  window.location.reload();
});

export default app;
