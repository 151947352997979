import { components as OpenAPI } from "@/autogen/openapi";

export const PARAMETERS = [
  "status",
  "email_type",
  "source",
  "automation",
] as const;
type ParameterTuple = typeof PARAMETERS;
export type Parameter = ParameterTuple[number];

export type Type = OpenAPI["schemas"]["EmailType"];

export const PRETTY_TYPE: Record<Type, string> = {
  public: "Public",
  premium: "Premium",
  private: "Private",
  free: "Free",
  churned: "Churned",
  archival: "Archival",
  hidden: "Hidden",
};

export type Status = OpenAPI["schemas"]["EmailStatus"];

export const PRETTY_STATUS: Record<Status, string> = {
  draft: "Draft",
  about_to_send: "About to send",
  scheduled: "Scheduled",
  in_flight: "In flight",
  imported: "Imported",
  deleted: "Deleted",
  errored: "Error",
  managed_by_rss: "Automated",
  sent: "Sent",
  paused: "Paused",
};

export type Source = OpenAPI["schemas"]["EmailSource"];

export const PRETTY_SOURCE: Record<Source, string> = {
  api: "API",
  app: "App",
  import: "Imported",
  external_feed: "RSS",
};

export type Email = OpenAPI["schemas"]["Email"];
