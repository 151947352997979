<template>
  <StandardTransition>
    <MenuItems
      ref="menuItems"
      class="p-2 z-20 bg-gray-900 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      :static="isStatic"
    >
      <slot></slot>
    </MenuItems>
  </StandardTransition>
</template>

<script lang="ts" setup>
import { MenuItems } from "@headlessui/vue";
import { ref, watch } from "vue";

import { useFloating } from "@/lib/popper";
import StandardTransition from "@/scaffolding/StandardTransition.vue";

const props = defineProps<{
  trigger: HTMLElement | null;
  isStatic?: boolean;
}>();

const menuItems = ref<any>();
const [floatTrigger, popper] = useFloating({
  placement: "bottom-end",
  distance: 10,
});
watch(
  () => props.trigger,
  (node) => (floatTrigger.value = node),
  { immediate: true }
);
watch(
  () => menuItems.value?.el,
  (node) => (popper.value = node),
  { immediate: true }
);
</script>
