import { ref } from "vue";

import { components as OpenAPI } from "@/autogen/openapi";
import api from "@/lib/api/base";
import { useStore } from "@/store/stores/notifications";
import { notify, uuid } from "@/utils";

const TIMEOUT_BEFORE_SHOWING_POPUP_IN_MILLISECONDS = 300 * 1000;

const recursivelyPoll = (id: string, callback?: () => void) => {
  api
    .path("/exports/{id}")
    .method("get")
    .create()({ id })
    .then((ex) => {
      if (ex.data.status === "ready") {
        notify("Done!", "success");
        if (ex.data.url) {
          window.location.href = ex.data.url;
        }
        if (callback) {
          callback();
        }
      } else {
        setTimeout(() => {
          recursivelyPoll(id, callback);
        }, 1000);
      }
    });
};

const useExport = (collections: OpenAPI["schemas"]["ExportCollection"][]) => {
  const pending = ref(false);
  const notificationsStore = useStore();

  const exportData = async () => {
    notify("Exporting...", "info");
    pending.value = true;

    api
      .path("/exports")
      .method("post")
      .create()({
        collections,
      })
      .then((ex) => {
        if (ex.data.status === "ready") {
          pending.value = false;
          notify("Done!", "success");
          if (ex.data.url) {
            window.location.href = ex.data.url;
          }
        } else {
          const timeout = setTimeout(() => {
            notificationsStore.notifications.push({
              id: uuid(),
              headline: "Your export is taking longer than expected.",
              description:
                "Sorry about that! The system is under load right now (it's an 'us' problem, not a 'you' problem.) We'll email you the export when it's ready; you can close this page.",
              route: null,
              creation_date: new Date().toISOString(),
            });
          }, TIMEOUT_BEFORE_SHOWING_POPUP_IN_MILLISECONDS);
          recursivelyPoll(ex.data.id, () => {
            pending.value = false;
            clearTimeout(timeout);
          });
        }
      });
  };

  return {
    exportData,
    pending,
  };
};

export default useExport;
