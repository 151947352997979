<template>
  <input
    :id="id"
    v-model="modelValue"
    type="checkbox"
    :indeterminate="indeterminate"
    :name="name"
    class="text-blue-500 focus:ring-0 focus:ring-offset-0 border-gray-300 rounded transition-colors"
  />
</template>

<script lang="ts" setup>
defineProps<{
  indeterminate?: boolean;
  id?: string;
  name?: string;
}>();

const modelValue = defineModel<boolean>("modelValue");
</script>
