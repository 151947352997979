<template>
  <div
    :class="{
      'hidden lg:flex flex-1 items-center space-x-2 rounded-md p-1 text-sm px-2 cursor-pointer': true,
      'bg-gray-600 text-white': active,
      'hover:bg-gray-200': !active,
    }"
  >
    <div>{{ defaultParameter.name }}</div>
    <div
      v-if="count !== undefined"
      :class="{
        'text-xs text-gray-400 font-bold font-mono': true,
        'text-gray-300': active,
      }"
    >
      {{ count }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import { DefaultParameter } from "./lib";

const props = defineProps<{
  defaultParameter: DefaultParameter<string>;
  active: boolean;
}>();

const count = computed(() =>
  props.defaultParameter.fetchCount(props.defaultParameter.parameters)
);
</script>
