<template>
  <TransitionRoot :show="props.open">
    <Teleport to="body">
      <div
        class="fixed z-10 inset-0 flex flex-col overflow-hidden overflow-y-auto"
      >
        <div class="h-[50vh] shrink-0"></div>

        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4"
          enter-to="opacity-100 translate-y-0"
          leave="ease-in duration-100"
          leave-from="opacity-100 translate-y-0"
          leave-to="opacity-0 translate-y-4"
        >
          <MenuItems
            static
            class="mt-auto p-4 z-20 bg-gray-900 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <slot></slot>
          </MenuItems>
        </TransitionChild>
      </div>
    </Teleport>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { MenuItems, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { watch } from "vue";

import { useStore as useScaffoldingStore } from "@/store/stores/scaffolding";

const scaffoldingStore = useScaffoldingStore();
const props = defineProps<{ open: boolean }>();

watch(
  () => props.open,
  (isOpen) => {
    scaffoldingStore.toggleDialog(isOpen);
  }
);
</script>
