<template>
  <Menu as="div" class="relative z-20 select-none">
    <div
      class="m-0 relative border border-solid border-gray-200 bg-white rounded-md text-sm font-medium text-gray-600 hover:text-gray-800 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-300 h-auto items-center flex p-1 lg:!space-x-1"
    >
      <DefaultParameterButton
        v-for="defaultParameter in defaultParameters"
        :key="defaultParameter.name"
        :default-parameter="defaultParameter"
        :active="activeDefaultParameter == defaultParameter.name"
        @click="
          () => {
            state = JSON.parse(JSON.stringify(defaultParameter.parameters));
          }
        "
      />
      <MenuButton
        :class="{
          '!m-0 relative space-x-2 rounded-md text-sm focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-300 items-center flex px-2 py-1 font-semibold': true,
          'bg-white text-gray-600 hover:bg-gray-200 ':
            activeDefaultParameter !== undefined,
          'bg-gray-600 !text-white hover:bg-gray-700':
            activeDefaultParameter === undefined,
        }"
      >
        <div>Filter</div>
        <div
          v-if="activeDefaultParameter === undefined"
          class="text-xs font-bold font-mono text-gray-300 hidden lg:block"
        >
          {{ count }}
        </div>
      </MenuButton>
    </div>
    <MenuItems
      class="absolute right-0 mt-2 w-64 bg-white rounded-md shadow-lg z-10 ring-1 ring-black ring-opacity-5 overflow-y-scroll max-h-[calc(100vh-70px)]"
    >
      <div v-for="(filter, index) in parameterSet" :key="filter.id">
        <ExpandButton
          :title="filter.name"
          :open="open.includes(filter.id)"
          :last="index === parameterSet.length - 1"
          :hint-inside="
            state[filter.id]?.length > 0 &&
            (filter.type !== 'checkboxes' ||
              state[filter.id]?.length < filter.checkboxes.length)
          "
          @click="
            open.includes(filter.id)
              ? (open = open.filter((id) => id !== filter.id))
              : open.push(filter.id)
          "
        />
        <ConfigurationWell
          :open="open.includes(filter.id)"
          :last="index === parameterSet.length - 1"
        >
          <div v-if="filter.type === 'date'" class="-mx-2 -mb-2">
            <DateInput
              id="start"
              label="Start date"
              :model-value="state[filter.id] ? state[filter.id][0] : undefined"
              @update:model-value="
                (event) => {
                  state[filter.id][0] = event;
                }
              "
            />
            <DateInput
              id="end"
              label="End date"
              :model-value="state[filter.id] ? state[filter.id][1] : undefined"
              @update:model-value="
                (event) => {
                  state[filter.id][1] = event;
                }
              "
            />
          </div>
          <template v-if="filter.type === 'checkboxes'">
            <div
              v-for="field in filter.checkboxes"
              :key="field"
              class="flex space-x-2 text-sm items-center"
            >
              <input
                :id="field"
                type="checkbox"
                :checked="state[filter.id]?.includes(field)"
                class="form-checkbox rounded bg-gray-200 text-blue-500 focus:ring-blue-200 transition-colors border-gray-300"
                @click="
                  (event) =>
                    onCheckboxClick({
                      event,
                      filterId: filter.id,
                      fieldId: field,
                    })
                "
              />
              <ParameterLabelWithCount
                :id="field.toString()"
                :count="
                  aggregates?.[filter.id]?.find(
                    (s) => s.parameter.toString() === field.toString()
                  )?.count
                "
              >
                <component
                  :is="filter.component || DefaultParameterLabel"
                  :id="field"
                />
              </ParameterLabelWithCount>
            </div>
          </template>
        </ConfigurationWell>
      </div>
    </MenuItems>
  </Menu>
</template>

<script lang="ts">
/**
 * If type = checkboxes, state[id] = [premium, churning]
 */

import { Menu, MenuButton, MenuItems } from "@headlessui/vue";
import { defineComponent, PropType } from "vue";

import DateInput from "@/components/Utilities/Forms/DateInput.vue";
import { Aggregate } from "@/types/aggregate";

import ConfigurationWell from "./ConfigurationWell.vue";
import DefaultParameterButton from "./DefaultParameterButton.vue";
import DefaultParameterLabel from "./DefaultParameterLabel.vue";
import ExpandButton from "./ExpandButton.vue";
import { DefaultParameter, Parameter, ParameterSet } from "./lib";
import ParameterLabelWithCount from "./ParameterLabelWithCount.vue";

type CheckboxSnapshot = {
  filterId: string;
  fieldId: string;
  state: boolean;
};

export default defineComponent({
  name: "ParameterWidget",

  components: {
    Menu,
    MenuButton,
    MenuItems,
    ExpandButton,
    ConfigurationWell,
    DefaultParameterButton,
    DateInput,
    ParameterLabelWithCount,
  },
  props: {
    parameterSet: {
      type: Array as PropType<ParameterSet<string>[]>,
      required: true,
    },
    defaultParameters: {
      type: Object as PropType<DefaultParameter<string>[]>,
      required: false,
    },
    aggregates: {
      type: Object as PropType<Record<string, Aggregate<string>[] | undefined>>,
      required: false,
    },
    modelValue: {
      type: Object as PropType<Parameter<string>>,
      required: true,
    },
    count: {
      type: Number,
      required: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      state: <Parameter<string>>{},
      open: [] as string[],
      lastChanged: <CheckboxSnapshot | null>null,
      activeDefaultParameter: <string | undefined | null>null,
    };
  },
  computed: {
    DefaultParameterLabel() {
      return DefaultParameterLabel;
    },
  },
  watch: {
    modelValue: {
      handler(_modelValue) {
        this.state = _modelValue;
      },
      deep: true,
    },
    state: {
      handler(_state) {
        this.$emit("update:modelValue", _state);
        this.activeDefaultParameter = (this.defaultParameters || []).find(
          (defaultParameter) =>
            JSON.stringify(defaultParameter.parameters) ===
            JSON.stringify(_state)
        )?.name;
      },
      deep: true,
    },
  },
  mounted() {
    this.state = this.modelValue;
  },
  methods: {
    onCheckboxClick({
      event,
      filterId,
      fieldId,
    }: {
      event: MouseEvent;
      filterId: string;
      fieldId: string;
    }) {
      const newState = (event.target as HTMLInputElement).checked;
      this.setCheckboxState({ filterId, fieldId, state: newState });

      if (
        event.shiftKey &&
        this.lastChanged &&
        this.lastChanged.filterId === filterId &&
        this.lastChanged.state === newState
      ) {
        this.setCheckboxesBetween({
          filterId: filterId,
          between: [this.lastChanged.fieldId, fieldId],
          state: newState,
        });
      }

      this.lastChanged = {
        filterId: filterId,
        fieldId: fieldId,
        state: newState,
      };
    },
    setCheckboxState({ filterId, fieldId, state }: CheckboxSnapshot) {
      if (state === true) {
        if (!this.state[filterId]) {
          this.state[filterId] = [];
        }
        this.state[filterId].push(fieldId);
      }

      if (state === false) {
        this.state[filterId] = this.state[filterId].filter(
          (id) => id !== fieldId
        );
        if (this.state[filterId].length === 0) {
          delete this.state[filterId];
        }
      }
    },
    setCheckboxesBetween({
      filterId,
      between,
      state,
    }: {
      filterId: string;
      between: [string, string];
      state: boolean;
    }) {
      const parameter = this.parameterSet.find(
        (filter) => filter.id === filterId
      );

      if (!parameter) return;

      if (parameter.type !== "checkboxes") return;

      const possibleCheckboxes = parameter.checkboxes;

      if (!possibleCheckboxes) return;

      let isBetween = false;
      for (const checkbox of possibleCheckboxes) {
        if (between.includes(checkbox)) {
          isBetween = !isBetween;
        }

        if (isBetween) {
          this.setCheckboxState({ filterId, fieldId: checkbox, state });
        }
      }
    },
  },
});
</script>
