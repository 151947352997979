<template>&nbsp;</template>

<script lang="ts" setup>
import Noty from "noty";
import { onMounted, onUnmounted, ref, watch } from "vue";

import type { components as OpenAPI } from "@/autogen/openapi";
import useInterval from "@/lib/interval";
import { useStore as useBulkActionsStore } from "@/store/stores/bulk_actions";

const props = defineProps<{
  bulkAction: OpenAPI["schemas"]["BulkAction"] & {
    onSuccess?: () => void;
  };
}>();

const notification = ref<Noty>(
  new Noty({
    text: "",
    type: "success",
    progressBar: false,
    timeout: false,
  })
);

const BULK_ACTION_TYPE_TO_STRING = {
  apply_tags: "Applying tag",
  ban_subscribers: "Banning subscriber",
  apply_metadata: "Applying metadata",
  delete_subscribers: "Deleting subscriber",
  delete_comments: "Deleting comment",
  delete_emails: "Deleting email",
  delete_tags: "Deleting tag",
  delete_surveys: "Deleting survey",
  gift_subscribers: "Granting gift subscription",
  reactivate_subscribers: "Reactivating subscriber",
  mark_subscribers_as_not_spammy: "Restoring subscriber",
  resubscribe_subscribers: "Resubscribing subscriber",
  send_emails: "Sending email",
  send_reminders: "Sending reminder",
  replay_events: "Replaying event",
  unsubscribe_subscribers: "Unsubscribing subscriber",
  update_email_types: "Updating email type",
  update_survey_statuses: "Updating survey status",
};

const store = useBulkActionsStore();

watch(
  props.bulkAction,
  (newBulkAction) => {
    notification.value.setText(
      `${BULK_ACTION_TYPE_TO_STRING[newBulkAction.type]}${
        (newBulkAction.metadata.ids === undefined
          ? []
          : (newBulkAction.metadata.ids as string[])
        ).length > 1
          ? "s"
          : ""
      }...`,
      true
    );
  },
  {
    immediate: true,
  }
);

useInterval(() => {
  store.retrieveBulkAction(props.bulkAction.id);
}, 1000);

onMounted(() => {
  notification.value.show();
});

onUnmounted(() => {
  notification.value.close();
  if (props.bulkAction.onSuccess !== undefined) {
    props.bulkAction.onSuccess();
  }
});
</script>
