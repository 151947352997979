import type { Component } from "vue";
import { RouteNamedMap } from "vue-router/auto-routes";

import BellIcon from "@/icons/heroicons/bell-micro.svg";
import BookOpenIcon from "@/icons/heroicons/book-open-micro.svg";
import CalendarIcon from "@/icons/heroicons/calendar-micro.svg";
import ChartBarIcon from "@/icons/heroicons/chart-bar-micro.svg";
import ChatBubbleLeftRightIcon from "@/icons/heroicons/chat-bubble-left-right-micro.svg";
import ClipboardIcon from "@/icons/heroicons/clipboard-micro.svg";
import CodeBracketIcon from "@/icons/heroicons/code-bracket-micro.svg";
import CogIcon from "@/icons/heroicons/cog-6-tooth-micro.svg";
import CreditCardIcon from "@/icons/heroicons/credit-card-micro.svg";
import EnvelopeIcon from "@/icons/heroicons/envelope-micro.svg";
import ExclamationTriangleIcon from "@/icons/heroicons/exclamation-triangle-micro.svg";
import EyeIcon from "@/icons/heroicons/eye-micro.svg";
import GiftIcon from "@/icons/heroicons/gift-micro.svg";
import GlobeAltIcon from "@/icons/heroicons/globe-alt-micro.svg";
import KeyIcon from "@/icons/heroicons/key-micro.svg";
import LockClosedIcon from "@/icons/heroicons/lock-closed-micro.svg";
import PaintBrushIcon from "@/icons/heroicons/paint-brush-micro.svg";
import PlayIcon from "@/icons/heroicons/play-micro.svg";
import RSSIcon from "@/icons/heroicons/rss-micro.svg";
import ShoppingBagIcon from "@/icons/heroicons/shopping-bag-micro.svg";
import SparkleIcon from "@/icons/heroicons/sparkles-micro.svg";
import TagIcon from "@/icons/heroicons/tag-micro.svg";
import UserGroupIcon from "@/icons/heroicons/user-group-micro.svg";
import UserIcon from "@/icons/heroicons/user-micro.svg";
import { RouteLocation } from "@/router/types";
import { Permission } from "@/types/account";
import { Newsletter } from "@/types/newsletter";

export type RouteConfiguration = {
  title: string;
  matches?: readonly string[];
  icon: Component;
  color?: string;
  hide?: (
    permissions: Permission["permissions"],
    newsletter: Newsletter
  ) => boolean;
  route: RouteLocation;
};

export type RouteToConfiguration<
  Path extends keyof Omit<RouteNamedMap, "/[...path]">
> = {
  [key in Path]: RouteConfiguration;
};

export const SEARCH_CONTEXT: RouteToConfiguration<
  | "/emails"
  | "/emails/new"
  | "/analytics"
  | "/automations"
  | "/automations/new"
  | "/surveys"
  | "/surveys/new"
  | "/subscribers"
  | "/tags"
  | "/tags/new"
  | "/webhooks"
  | "/webhooks/new"
  | "/settings/basics"
  | "/settings/design"
  | "/settings/team"
  | "/settings/domains"
  | "/settings/team/new"
  | "/emails/imports/new"
  | "/events"
  | "/feeds"
  | "/feeds/new"
  | "/requests"
  | "/requests/api-key/"
  | "/sponsorships"
  | "/settings/billing"
  | "/settings/integrations"
  | "/settings/embedding"
  | "/settings/paid-subscriptions"
  | "/settings/subscribing"
  | "/settings/tracking"
  | "/settings/profile"
  | "/settings/notifications"
  | "/settings/security"
  | "/settings/referrals"
  | "/settings/subscriptions"
  | "/settings/danger-zone"
  | "/comments"
> = {
  "/comments": {
    title: "Comments",
    icon: ChatBubbleLeftRightIcon,
    route: {
      name: "/comments",
    },
    hide: (p, n) => n.enabled_features.includes("comments") === false,
  },
  "/emails": {
    title: "Emails",
    icon: EnvelopeIcon,
    route: {
      name: "/emails",
    },
  },
  "/emails/new": {
    title: "Create a new email",
    icon: EnvelopeIcon,
    route: {
      name: "/emails/new",
    },
  },
  "/analytics": {
    title: "Analytics",
    icon: ChartBarIcon,
    route: {
      name: "/analytics",
    },
  },
  "/automations": {
    title: "Automations",
    icon: SparkleIcon,
    route: {
      name: "/automations",
    },
    hide: (p, n) =>
      n.enabled_features.includes("automations") === false ||
      p.automations === undefined ||
      p.automations === "none",
  },
  "/automations/new": {
    title: "Create a new automation",
    icon: SparkleIcon,
    route: {
      name: "/automations/new",
    },
    hide: (p, n) =>
      n.enabled_features.includes("automations") === false ||
      p.automations !== "write",
  },
  "/surveys": {
    title: "Surveys",
    icon: ClipboardIcon,
    route: {
      name: "/surveys",
    },
    hide: (p, n) =>
      n.enabled_features.includes("surveys") === false ||
      p.surveys === undefined ||
      p.surveys === "none",
  },
  "/surveys/new": {
    title: "Create a new survey",
    icon: UserGroupIcon,
    route: {
      name: "/surveys/new",
    },
    hide: (p, n) =>
      n.enabled_features.includes("surveys") === false || p.surveys !== "write",
  },
  "/subscribers": {
    title: "Subscribers",
    icon: UserGroupIcon,
    route: {
      name: "/subscribers",
    },
  },
  "/tags": {
    title: "Tags",
    icon: TagIcon,
    route: {
      name: "/tags",
    },
    hide: (p, n) => n.enabled_features.includes("tags") === false,
  },
  "/tags/new": {
    title: "Create a new tag",
    icon: TagIcon,
    route: {
      name: "/tags/new",
    },
    hide: (p, n) => n.enabled_features.includes("tags") === false,
  },
  "/webhooks": {
    title: "Webhooks",
    icon: CodeBracketIcon,
    route: {
      name: "/webhooks",
    },
    hide: (p, n) => n.enabled_features.includes("webhooks") === false,
  },
  "/webhooks/new": {
    title: "Create a new webhook",
    icon: CodeBracketIcon,
    route: {
      name: "/webhooks/new",
    },
    hide: (p, n) => n.enabled_features.includes("webhooks") === false,
  },
  "/settings/basics": {
    title: "Basics",
    matches: ["Settings", "Features", "Timezone", "Locale"],
    icon: CogIcon,
    route: {
      name: "/settings/basics",
    },
  },
  "/settings/design": {
    title: "Design",
    matches: ["Web template"],
    icon: PaintBrushIcon,
    hide: (p) => p.administrivia === undefined,
    route: {
      name: "/settings/design",
    },
  },
  "/settings/team": {
    title: "Team",
    icon: UserGroupIcon,
    hide: (p) => p.administrivia === undefined,
    route: {
      name: "/settings/team",
    },
  },
  "/settings/domains": {
    title: "Domains",
    matches: ["Hosting domain", "Sending domain"],
    icon: GlobeAltIcon,
    route: {
      name: "/settings/domains",
    },
  },
  "/settings/team/new": {
    title: "Invite a new team member",
    icon: UserIcon,
    route: {
      name: "/settings/team/new",
    },
  },
  "/emails/imports/new": {
    title: "Import emails",
    icon: EnvelopeIcon,
    route: {
      name: "/emails/imports/new",
    },
  },
  "/events": {
    title: "Events",
    icon: CalendarIcon,
    route: {
      name: "/events",
    },
  },
  "/feeds": {
    title: "Feeds",
    icon: RSSIcon,
    route: {
      name: "/feeds",
    },
    hide: (p, n) => n.enabled_features.includes("rss") === false,
  },
  "/feeds/new": {
    title: "Connect an RSS feed",
    icon: RSSIcon,
    route: {
      name: "/feeds/new",
    },
    hide: (p, n) => n.enabled_features.includes("rss") === false,
  },
  "/requests": {
    title: "API Requests",
    icon: CodeBracketIcon,
    route: {
      name: "/requests",
    },
    hide: (p, n) => n.enabled_features.includes("api") === false,
  },
  "/requests/api-key/": {
    title: "API Key",
    icon: KeyIcon,
    route: {
      name: "/requests/api-key/",
    },
    hide: (p, n) => n.enabled_features.includes("api") === false,
  },
  "/sponsorships": {
    title: "Sponsorships",
    icon: ShoppingBagIcon,
    route: {
      name: "/sponsorships",
    },
    hide: (p, n) => n.enabled_features.includes("sponsorships") === false,
  },
  "/settings/billing": {
    title: "Billing",
    icon: CreditCardIcon,
    route: {
      name: "/settings/billing",
    },
  },
  "/settings/integrations": {
    title: "Integrations",
    icon: PlayIcon,
    hide: (p) => p.administrivia === undefined,
    route: {
      name: "/settings/integrations",
    },
  },
  "/settings/embedding": {
    title: "Embedding",
    icon: CodeBracketIcon,
    route: {
      name: "/settings/embedding",
    },
  },
  "/settings/paid-subscriptions": {
    title: "Paid subscriptions",
    icon: CreditCardIcon,
    route: {
      name: "/settings/paid-subscriptions",
    },
  },
  "/settings/subscribing": {
    title: "Subscribing",
    matches: ["Unsubscription reasons"],
    icon: RSSIcon,
    hide: (p) => p.administrivia === undefined,
    route: {
      name: "/settings/subscribing",
    },
  },
  "/settings/tracking": {
    title: "Tracking",
    icon: EyeIcon,
    matches: ["Click tracking", "Open tracking", "UTM"],
    route: {
      name: "/settings/tracking",
    },
  },
  "/settings/profile": {
    title: "Profile",
    icon: UserIcon,
    route: {
      name: "/settings/profile",
    },
  },
  "/settings/notifications": {
    title: "Notifications",
    icon: BellIcon,
    route: {
      name: "/settings/notifications",
    },
  },
  "/settings/security": {
    title: "Security",
    icon: LockClosedIcon,
    route: {
      name: "/settings/security",
    },
  },
  "/settings/referrals": {
    title: "Referrals",
    icon: GiftIcon,
    route: {
      name: "/settings/referrals",
    },
  },
  "/settings/subscriptions": {
    title: "Subscriptions",
    icon: BookOpenIcon,
    route: {
      name: "/settings/subscriptions",
    },
  },
  "/settings/danger-zone": {
    title: "Danger Zone",
    icon: ExclamationTriangleIcon,
    route: {
      name: "/settings/danger-zone",
    },
    // Note: we should also check to see if they have more than one newsletter.
    hide: (p, n) => n.hidden_settings.length === 0,
  },
} as const;
