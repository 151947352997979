<template>
  <MenuItems
    ref="menuItems"
    class="p-2 z-20 bg-gray-900 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    :static="open"
  >
    <slot></slot>
  </MenuItems>
</template>

<script lang="ts" setup>
import { MenuItems } from "@headlessui/vue";
import { shallowRef } from "vue";

const menuItems = shallowRef();
defineExpose({
  getMenuItems() {
    return menuItems.value;
  },
});

defineProps<{
  open?: boolean;
}>();
</script>
